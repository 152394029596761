import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

function TwoButtonsModal({ title, question, buttons }) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{question}</p>
        </Modal.Body>
        <Modal.Footer>
          {buttons.map((button, index) => (
            <Button
              key={index}
              variant={button.variant}
              onClick={button.action}
              className={button.className}
            >
              {button.label}
            </Button>
          ))}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TwoButtonsModal;
