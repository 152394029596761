import { createContext } from "react";

const AuthContext = createContext(null);
const TOKEN_NAME = 'JWT-LQD-VESTA'

export const AuthProvider = ({ children }) => {
    const getAuth = () => sessionStorage.getItem(TOKEN_NAME)
    const setAuth = (token) => sessionStorage.setItem(TOKEN_NAME, token)
    const deleteAuth = () => sessionStorage.removeItem(TOKEN_NAME)
    const loggedIn = () => !!getAuth()

    return (
        <AuthContext.Provider value={{ getAuth, setAuth, deleteAuth, loggedIn }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;