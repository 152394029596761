import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Form, Container, Col, Row, ListGroup, Button } from "react-bootstrap"
import classNames from 'classnames'
import Loading from '../components/modal/Loading'
import AlertModal from '../components/modal/AlertModal'
import TwoButtonsModal from '../components/modal/TwoButtonsModal';
import useApi from '../hooks/useApi'
import './_style.css'

const Settings = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { deleteCamera, getCameras, getVideoConf, putVideoConf } = useApi()
    
    const [preLength, setPreLength] = useState()
    const [preLengthCur, setPreLengthCur] = useState()
    const [postLength, setPostLength] = useState()
    const [postLengthCur, setPostLengthCur] = useState()
    const [segmentLength, setSegmentLength] = useState()
    const [callInProgress, setCallInProgress] = useState(false);
    const [alert, setAlert] = useState()
    const [showPopup, setShowPopup] = useState(false)
    const [deleteCamId, setDeleteCamId] = useState()

    const fillVideoConf = ({ preLength, postLength, segmentLength }) => {
        setPreLength(parseInt(preLength))
        setPreLengthCur(parseInt(preLength))
        setPostLength(parseInt(postLength))
        setPostLengthCur(parseInt(postLength))
        setSegmentLength(parseInt(segmentLength))
    }

    const videoQuery = useQuery('video', getVideoConf, {
        onSuccess: response => fillVideoConf(response.data)
    })

    const videoMutation = useMutation(putVideoConf, {
        onMutate: () => {
            // Prima della chiamata, impostiamo callInProgress su true
            setCallInProgress(true);
        },
        onSuccess: response => {
            // Dopo che la chiamata è completata con successo, impostiamo callInProgress su false
            setCallInProgress(false);
            fillVideoConf(response.data);
        },
        onError: () => {
            // In caso di errore, impostiamo callInProgress su false
            setCallInProgress(false);
            // Puoi gestire l'errore qui se necessario
            setAlert("Errore. La sua richiesta non è stata eseguita.")
        },
        onSettled: () => {
            // In qualsiasi caso, dopo che la chiamata è completata (successo o errore), impostiamo callInProgress su false
            setCallInProgress(false);
        },
    });


    const camerasQuery = useQuery('cameras', getCameras)

    const cameraDeleteMutation = useMutation(camId => deleteCamera(camId), {
        onMutate: () => {
            // Prima della chiamata, impostiamo callInProgress su true
            setCallInProgress(true);
        },
        onSuccess: response => {
            // Dopo che la chiamata è completata con successo, impostiamo callInProgress su false
            setCallInProgress(false);
        },
        onError: () => {
            // In caso di errore, impostiamo callInProgress su false
            setCallInProgress(false);
            // Puoi gestire l'errore qui se necessario
            setAlert("Errore. La sua richiesta non è stata eseguita.")
        },
        onSettled: () => {
            // In qualsiasi caso, dopo che la chiamata è completata (successo o errore), impostiamo callInProgress su false
            setCallInProgress(false);
            queryClient.invalidateQueries('cameras')
        },
    })

    function discardVideoConf() {
        setPreLength(preLengthCur)
        setPostLength(postLengthCur)
    }


    let modifiedVideo = preLength !== preLengthCur || postLength !== postLengthCur

    return (

        <>
            <Loading isLoading={callInProgress || videoQuery.isFetching || camerasQuery.isFetching} />

            {
                !videoQuery.isLoading && !camerasQuery.isLoading && <>

                    <Container>
                        <Row>
                            <Col sm={12} lg={6} className="mb-4">
                                <h3 className="text-primary"><i className="bi bi-play-btn-fill"></i> Video</h3>
                                <Form className="mt-4">
                                    <Form.Group>
                                        <Form.Label className="text-center">
                                            <b>Lunghezza pre-evento (secondi)</b>
                                        </Form.Label>

                                        <div className="d-flex align-items-center">
                                            <input
                                                type="range"
                                                id="rangeInput"
                                                value={preLength}
                                                min={2}
                                                max={20}
                                                step={1}
                                                onChange={e => setPreLength(parseInt(e.target.value))}
                                                className="w-100"
                                            />

                                        </div>
                                        <span>Valore attuale: {preLength}</span>


                                    </Form.Group>
                                    <Form.Group className="mt-3 mb-3">
                                        <Form.Label className="text-center">
                                            <b>Lunghezza post-evento (secondi)</b>
                                        </Form.Label>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="range"
                                                id="rangeInput"
                                                value={postLength}
                                                min={2}
                                                max={20}
                                                step={1}
                                                onChange={e => setPostLength(parseInt(e.target.value))}
                                                className="w-100"
                                            />

                                        </div>
                                        <span >Valore attuale: {postLength}</span>

                                    </Form.Group>



                                    <div className="d-flex justify-content-start">
                                        <Button
                                            id="discard-video-btn"
                                            name="discard-video-btn"
                                            className="text-white me-1"
                                            variant="primary"
                                            onClick={discardVideoConf}
                                            disabled={!modifiedVideo}
                                        >
                                            Annulla
                                        </Button>
                                        <Button
                                            id="settings-video-btn"
                                            name="settings-video-btn"
                                            className={classNames("text-white", modifiedVideo && "btnHighlight")}
                                            variant="primary"
                                            onClick={e => {
                                                e.preventDefault()
                                                videoMutation.mutate({
                                                    'preLength': preLength,
                                                    'postLength': postLength,
                                                    'segmentLength': segmentLength,
                                                })
                                            }}
                                        >
                                            Salva
                                        </Button>
                                    </div>
                                </Form>

                            </Col>
                            <Col sm={12} lg={6} className="mb-4">
                                <h3 className="text-primary"><i className="bi bi-camera-video-fill"></i> Telecamere</h3>

                                {!camerasQuery.isLoading && camerasQuery.isError && <p>Errore: {camerasQuery.error.message}</p>}

                                {!camerasQuery.isLoading && !camerasQuery.isError && (
                                    <>
                                        {camerasQuery.data.data?.length > 0 ? (



                                            <ListGroup>
                                                {
                                                    camerasQuery.data.data.map((c, index) => {
                                                        // separazione stream url in tre campi
                                                        let host, hostStartIndex, hostEndIndex 
                                                        if (c.address) {
                                                            // da c.address= rtsp://admin:admin@192.168.1.168:80/1
                                                            // estraggo host= 192.168.1.168
                                                            hostStartIndex = Math.max(c.address.indexOf('//') + 2, c.address.indexOf('@') + 1)
                                                            hostEndIndex = Math.min(...[c.address.indexOf(':', hostStartIndex), c.address.indexOf('/', hostStartIndex)].filter(a => a > 0))
                                                            host = c.address.slice(hostStartIndex, hostEndIndex)
                                                        }

                                                        return (

                                                            <ListGroup.Item className={`d-flex justify-content-between align-items-center ${!c.enabled ? 'bg-gray' : ''}`}>
                                                                <div>
                                                                    <p className="mb-0"><b>{c.name}</b></p>
                                                                    <p className="mb-0"><small class="text-muted">{host}</small></p>
                                                                </div>

                                                                <div className="d-flex">
                                                                    <div className="me-4">
                                                                        
                                                                        <i className={`display-4 bi ${c.audio_enabled ? 'bi-volume-up-fill' : 'bi-volume-mute-fill'}`}></i>

                                                                    </div>
                                                                    <Button className="custom-button" color="link"
                                                                        onClick={() => {setShowPopup(true); setDeleteCamId(c.id)}}>
                                                                        <i className="white-text bi bi-trash-fill"></i>
                                                                    </Button>
                                                                    <Button className="custom-button ms-1" color="link" onClick={() => navigate(`/camera/${c.id}`)}>
                                                                        <i className="white-text bi bi-pencil-fill"></i>
                                                                    </Button>
                                                                </div>
                                                            </ListGroup.Item>





                                                        )
                                                    }

                                                    )
                                                }



                                            </ListGroup>

                                        ) : (
                                            <p>Nessuna telecamera</p>
                                        )}
                                    </>
                                )}

                                <div className="d-flex mt-2 justify-content-start">
                                    <Button
                                        id={`add-camera-btn`}
                                        name={`add-camera-btn`}
                                        className="text-white"
                                        variant="primary"
                                        onClick={() => navigate('/camera')}
                                    >
                                        Aggiungi
                                    </Button>
                                    <Button
                                        id={`autodetect-btn`}
                                        name={`autodetect-btn`}
                                        className="ms-1 text-white"
                                        variant="primary"
                                        onClick={() => navigate('/autodetect')}
                                    >
                                        Ricerca
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>


                </>
            }

            <AlertModal
                alert={alert}
                clear={() => setAlert(null)}
            />
            {showPopup && (
                <TwoButtonsModal
                    title="Eliminazione telecamera"
                    question="Sei sicuro di voler procedere?"
                    buttons={[
                        {
                            label: "Annulla",
                            variant: "secondary",
                            action: () => setShowPopup(false),
                        },
                        {
                            label: "Conferma",
                            variant: "primary",
                            className: "text-white",
                            action: () => {
                                setShowPopup(false)
                                cameraDeleteMutation.mutate(deleteCamId)
                                setDeleteCamId(null)
                            },
                        },
                    ]}
                />
            )}
        </>
    )
}

export default Settings