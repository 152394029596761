import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import useApi from '../hooks/useApi'
import { Form, Container, Col, Row, Button, ListGroup } from "react-bootstrap"
import Loading from '../components/modal/Loading'
import AlertModal from '../components/modal/AlertModal'
import TwoButtonsModal from '../components/modal/TwoButtonsModal'

const System = () => {
    const { getSystemDefaults, getSystemReboot, getSystemInfo, getUpdateInfo, putUpdateToVersion } = useApi()
    const navigate = useNavigate()

    const [sysInfo, setSysInfo] = useState()
    const [updVersion, setUpdVersion] = useState()
    const [allVersions, setAllVersions] = useState([])

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [showRebootPopup, setShowRebootPopup] = useState(false)
    const [showFactoryPopup, setShowFactoryPopup] = useState(false)
    const [showUpdatePopup, setShowUpdatePopup] = useState(false)

    // visualizza lo spinner per tot secondi, poi rimanda alla login
    const showLoadingThenRelogin = (waitSeconds) => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            navigate('/login', { replace: true })
        }, waitSeconds * 1000)
    }

    const { isFetching: isFetching1, isError: isError1 } = useQuery('sys-infp', getSystemInfo, {
        onSuccess: response => setSysInfo(response.data)
    })

    const { isFetching: isFetching2, isError: isError2 } = useQuery('upd-info', getUpdateInfo, {
        onSuccess: response => {
            setAllVersions(response.data)
            if (response.data?.length > 0) setUpdVersion(response.data[0])
        }
    })

    const fwUpdateMutation = useMutation(version => putUpdateToVersion(version), {
        onSuccess: () => setMessage("Aggiornamento in corso, attendere il riavvio del sistema"),
        onError: () => setMessage("Non è stato possibile effettuare l'aggiornamnto")
    })

    return (

        <>
            <Loading isLoading={isFetching1 || isFetching2 || loading} />
            <AlertModal
                alert={message}
                clear={() => setMessage(null)}
            />
            {showRebootPopup && (
                <TwoButtonsModal
                    title="Riavvio"
                    question="Sei sicuro di voler procedere?"
                    buttons={[
                        {
                            label: "Annulla",
                            variant: "secondary",
                            action: () => setShowRebootPopup(false),
                        },
                        {
                            label: "Conferma",
                            variant: "primary",
                            className: "text-white",
                            action: () => {
                                setShowRebootPopup(false)
                                getSystemReboot()
                                showLoadingThenRelogin(40)
                            }
                        },
                    ]}
                />
            )}
            {showFactoryPopup && (
                <TwoButtonsModal
                    title="Impostazioni di fabbrica"
                    question="Sei sicuro di voler procedere?"
                    buttons={[
                        {
                            label: "Annulla",
                            variant: "secondary",
                            action: () => setShowFactoryPopup(false),
                        },
                        {
                            label: "Conferma",
                            variant: "primary",
                            className: "text-white",
                            action: () => {
                                setShowFactoryPopup(false)
                                getSystemDefaults()
                                showLoadingThenRelogin(40)
                            }
                        },
                    ]}
                />
            )}
            {showUpdatePopup && (
                <TwoButtonsModal
                    title="Aggiornamento firmware"
                    question="L'operazione richiderà circa 2 minuti. Sei sicuro di voler procedere?"
                    buttons={[
                        {
                            label: "Annulla",
                            variant: "secondary",
                            action: () => setShowUpdatePopup(false),
                        },
                        {
                            label: "Conferma",
                            variant: "primary",
                            className: "text-white",
                            action: () => {
                                setShowUpdatePopup(false)
                                fwUpdateMutation(updVersion)
                                showLoadingThenRelogin(120)
                            }
                        },
                    ]}
                />
            )}

                    <Container>
                        <Row >
                            <Col sm={12} lg={6} className="mb-4">
                                <h3 className="text-primary"><i className="bi bi-display"></i> Sistema</h3>
                                <Row>
                                    <Col>

                                        {isError1 ? (
                                            <p>Non è stato possibile recuperare le informazioni di sistema</p>
                                        ) : (

                                            <ListGroup>
                                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                                    <span className="fw-bold">Versione corrente</span>
                                                    <span>{sysInfo?.system_version}</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                                    <span className="fw-bold">Hostname</span>
                                                    <span>{sysInfo?.hostname}</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                                    <span className="fw-bold">MAC Address</span>
                                                    <span>{sysInfo?.eth0_mac}</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                                    <span className="fw-bold">Serial Number</span>
                                                    <span>{sysInfo?.serial_number}</span>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        )}


                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex  justify-content-start">
                                            <Button
                                                id="system-defaults-btn"
                                                name="system-defaults-btn"
                                                className="mt-3 text-white"
                                                variant="primary"
                                                onClick={() => setShowFactoryPopup(true)}
                                            >
                                                Factory Reset
                                            </Button>
                                            <Button
                                                id="system-reboot-btn"
                                                name="system-reboot-btn"
                                                className="mt-3 ms-1 text-white"
                                                variant="primary"
                                                onClick={() => setShowRebootPopup(true)}
                                            >
                                                Reboot
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} lg={6} className="mb-4">
                                <h3 className="text-primary"><i className="bi bi-arrow-up-circle-fill"></i> Aggiornamento</h3>
                                {allVersions.length === 0 ? (
                                <p>Nessun aggiornamento disponibile</p>
                                ) : (
                                <div><p>E' disponibile la nuova versione XXX</p>
                                <Button
                                                id="system-update-btn"
                                                name="system-update-btn"
                                                className="text-white"
                                                variant="primary"
                                                onClick={() => setShowUpdatePopup(true)}
                                            >
                                                Aggiorna
                                            </Button>
                                </div>
                                )}
                            </Col>
                        </Row>


                    </Container>
                
        </>
    )
}

export default System