import React from 'react'
import { Link } from 'react-router-dom'

const Missing = () => {
    return (
        <main className='Main'>
            <h2>Pagina non disponibile</h2>
            <p>
                <Link to='/'>Ritorna alla Homepage</Link>
            </p>
        </main>
    )
}

export default Missing