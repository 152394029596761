import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Container, Col, Row, Button } from "react-bootstrap";
import classNames from 'classnames';
import Loading from '../components/modal/Loading'
import useApi from '../hooks/useApi'
import './_style.css'


const EVENT_MAX = 10
const EVENT_TYPES = [
    {
        id: 'AG',
        name: 'Allarme Gruppo',
        label: 'Gruppo',
        labelType: 'literal',
        valueMin: 0,
        valueMax: 7,
    },
    {
        id: 'AL',
        name: 'Allarme Linea',
        label: 'Linea',
        labelType: 'numeric',
        valueMin: 1,
        valueMax: 40,
    },
]


const Camera = () => {
    const navigate = useNavigate()
    let { cameraId } = useParams()
    const { getCamera, postCamera, putCamera } = useApi()

    const [name, setName] = useState('')
    const [enabled, setEnabled] = useState(true)
    const [audioEnabled, setAudioEnabled] = useState(true)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [url, setUrl] = useState('')
    const [events, setEvents] = useState([])
    const [enableQuery, setEnableQuery] = useState(true)

    const fillCameraConf = ({ name, enabled, audio_enabled, address, alarms }) => {
        // separazione stream url in tre campi
        // mi aspetto address del tipo rtsp://admin:admin@192.168.1.168:80/1
        let index1, index2, username, password, url
        if (address) {
            index1 = address.indexOf('//') + 2
            index2 = address.indexOf('@')
            username = address.slice(index1, index2).split(':')[0]
            password = address.slice(index1, index2).split(':')[1]
            url = address.slice(0, index1) + address?.slice(index2 + 1,)
        }

        setName(name)
        setEnabled(enabled)
        setAudioEnabled(audio_enabled)
        setUsername(username || '')
        setPassword(password || '')
        setUrl(url || '')

        let events
        if (alarms) {
            events = alarms.map(a => {
                let e = null
                let index = a.search(/\d+/)
                if (index > 0) {
                    let type = EVENT_TYPES.find(t => t.id === a.slice(0, index))
                    if (type) {
                        e = { type, value: parseInt(a.slice(index)) }
                    }
                }
                return e
            }).filter(e => !!e) // rimuove eventuali null
        }
        setEvents(events || [])
    }

    const { isFetching } = useQuery(['camera', cameraId], () => getCamera(cameraId), {
        onSuccess: response => { fillCameraConf(response.data); setEnableQuery(false) },
        enabled: !!cameraId && enableQuery,
    })

    const mutation = useMutation(cam => {
        return cam.id ? putCamera(cam.id, cam) : postCamera(cam)
    }, { onSuccess: () => navigate('/settings') })

    function createEvent() {
        let newEvents = events.slice()
        let eventType = EVENT_TYPES.at(0)
        newEvents.push({ type: eventType, value: eventType.valueMin })
        setEvents(newEvents)
    }

    function setNthEventType(i, value) {
        let newEvents = events.slice()
        let newType = EVENT_TYPES.find(t => t.id === value)
        newEvents[i].type = newType
        if (newEvents[i].value < newType.valueMin || newEvents[i].value > newType.valueMax) {
            newEvents[i].value = newType.valueMin
        }
        setEvents(newEvents)
    }

    function setNthEventValue(i, value) {
        let newEvents = events.slice()
        newEvents[i].value = value
        setEvents(newEvents)
    }

    function deleteNthEvent(n) {
        setEvents(events => events.filter((e, i) => i !== n))
    }

    function submitCamera(e) {
        e.preventDefault()

        // inserisco nell'indirizzo la stringa <username>:<password>@ dopo //
        let index = url.indexOf('//') + 2
        let address = url.slice(0, index) + `${username}:${password}@` + url.slice(index)
        let alarms = events.filter(e => e && e.type).map(e => `${e.type.id}${e.value}`)
        // elimina gli eventi duplicati
        alarms = [...new Set(alarms)]

        mutation.mutate({
            'id': cameraId,
            'name': name,
            'enabled': enabled,
            'audio_enabled': audioEnabled,
            'address': address,
            'alarms': alarms,
        })
    }

    return (
        <>
            <Loading isLoading={isFetching} />

            <Container>
                <Row >
                    <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-4">
                        <h3 className="text-primary"><i className="bi bi-camera-video-fill"></i> {cameraId ? 'Modifica telecamera' : 'Nuova telecamera'}</h3>
                        <Form className="mt-4">

                            <Form.Group
                                className="mb-3"

                            >
                                <Form.Label className="text-center">
                                    Nome
                                </Form.Label>
                                <Form.Control
                                    type='text'

                                    id="cameraName"
                                    name="cameraName"
                                    value={name}
                                    onChange={e => setName(e.target.value)}

                                />
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center justify-content-between">
                                <Form.Label className="me-3">Abilitata</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="cameraEnabled"
                                    name="cameraEnabled"
                                    checked={enabled}
                                    onChange={() => setEnabled((enabled) => !enabled)}
                                />
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center justify-content-between">
                                <Form.Label className="me-3">Audio abilitato</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="audioEnabled"
                                    name="audioEnabled"
                                    checked={audioEnabled}
                                    onChange={() => setAudioEnabled(enabled => !enabled)}
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"

                            >
                                <Form.Label className="text-center">
                                    Username
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    id="cameraUsername"
                                    name="cameraUsername"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}

                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"

                            >
                                <Form.Label className="text-center">
                                    Password
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    id="cameraPassword"
                                    name="cameraPassword"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}

                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"

                            >
                                <Form.Label className="text-center">
                                    URL
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    id="cameraUrl"
                                    name="cameraUrl"
                                    value={url}
                                    onChange={e => setUrl(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"

                            >
                                <Form.Label className="text-center">
                                    Eventi
                                </Form.Label>

                                {events.map((e, i) =>
                                    <div className="d-flex">
                                    <Form.Select
                                        className="mb-2 me-2"
                                        id={`event-${i}-type-sel`}
                                        value={e.type.id}
                                        onChange={e => setNthEventType(i, e.target.value)}
                                    >
                                        {EVENT_TYPES.map(t => <option value={t.id}>{t.name}</option>)}
                                    </Form.Select>
                                
                                    <Form.Select
                                        className="mb-2 me-2"
                                        id={`event-${i}-value-sel`}
                                        value={e.value}
                                        onChange={e => setNthEventValue(i, e.target.value)}
                                    >
                                        {[...Array(e.type.valueMax - e.type.valueMin + 1).keys()]
                                            .map(n => n + e.type.valueMin).map(n => {
                                                let label = e.type.labelType === 'literal' ? (
                                                    String.fromCharCode('A'.charCodeAt(0) + n - e.type.valueMin)
                                                ) : n.toString()
                                                return (
                                                    <option value={n}>{`${e.type.label} ${label}`}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>

                                    <Button className="custom-button" color="link"
                                    style={{ width: "20%", height: "100%" }}
                                                                        onClick={e => deleteNthEvent(i)}>
                                                                        <i className="white-text bi bi-trash-fill"></i>
                                                                    </Button>
                                
                                    
                                </div>
                                
                                )}

                                {(events.length < EVENT_MAX) && <div className="d-flex justify-content-start">
                                    <Button
                                        id="create-event-btn"
                                        name="create-event-btn"
                                        className="text-white"
                                        variant="primary"
                                        onClick={createEvent}
                                    >
                                        Nuovo Evento
                                    </Button>
                                </div>}
                            </Form.Group>

                            <div className="d-flex justify-content-end">
                                <Button
                                    id="camera-discard-btn"
                                    name="camera-discard-btn"
                                    className="text-white"
                                    variant="primary"
                                    onClick={() => navigate(-1)}
                                >
                                    Annulla
                                </Button>

                                <Button

                                    type="button"
                                    id="camera-btn"
                                    name="camera-btn"
                                    className="text-white ms-1"
                                    variant="primary"
                                    value="Salva"
                                    onClick={submitCamera}

                                >
                                    Salva
                                </Button>
                            </div>



                        </Form>
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default Camera