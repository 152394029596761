import { createContext } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import { apiBaseURL } from "../constants";

const ApiContext = createContext(null);

export const ApiProvider = ({ children }) => {
    const navigate = useNavigate()
    const { getAuth, deleteAuth } = useAuth()

    const publicApi = axios.create({
        baseURL: apiBaseURL,
        headers: { 'Content-Type': 'application/json' },
    });

    const api = axios.create({
        baseURL: apiBaseURL,
        headers: { 'Content-Type': 'application/json' },
    });

    // imposto il token di accesso per le api protette
    api.interceptors.request.use(
        config => {
            if (!config.headers['Authorization']) {
                config.headers['Authorization'] = `JWT ${getAuth()}`
            }
            return config
        },
        error => Promise.reject(error)
    )

    // ritorna alla login in caso di errore 401
    api.interceptors.response.use(
        response => response,
        async error => {
            if (error?.response?.status === 401) {
                deleteAuth()
                navigate('/login', { replace: true })
            }
            return Promise.reject(error);
        }
    )

    function login(username, password) {
        return publicApi.post('auth', { username, password })
    }

    function putProfile(username, pwdOld, pwdNew) {
        return api.put('profile', { username, 'password-old': pwdOld, 'password-new': pwdNew })
    }

    function getAutodetectDiscovery() {
        return api.get('autodetect/discovery')
    }

    function postAutodetectProfiles(data) {
        return api.post('autodetect/profiles', data)
    }

    function getEth0Conf() {
        return api.get('network/interfaces/eth0')
    }

    function putEth0Conf(conf) {
        return api.put('network/interfaces/eth0', conf)
    }

    function getCameras() {
        return api.get('settings/cameras')
    }

    function postCamera(cam) {
        return api.post('settings/cameras', cam)
    }

    function getCamera(id) {
        return api.get(`settings/cameras/${id}`)
    }

    function putCamera(id, cam) {
        return api.put(`settings/cameras/${id}`, cam)
    }

    function deleteCamera(id) {
        return api.delete(`settings/cameras/${id}`)
    }

    function deleteAllCameras() {
        return api.delete('settings/cameras')
    }

    function getVideoConf() {
        return api.get('settings/video')
    }

    function putVideoConf(conf) {
        return api.put('settings/video', conf)
    }

    function getStatus() {
        return api.get('status')
    }

    function getSystemDefaults() {
        return api.get('system/defaults')
    }

    function getSystemInfo() {
        return api.get('system/info')
    }

    function getSystemReboot() {
        return api.get('system/reboot')
    }

    function getSystemVersion() {
        return api.get('system/version')
    }

    function getUpdateInfo() {
        return api.get('system/update')
    }

    function putUpdateToVersion(version) {
        return api.put('system/update', { version })
    }

    return (
        <ApiContext.Provider value={{
            login,
            putProfile, getAutodetectDiscovery, postAutodetectProfiles,
            getEth0Conf, putEth0Conf,
            getCameras, postCamera, getCamera, putCamera, deleteCamera, deleteAllCameras,
            getVideoConf, putVideoConf, getStatus,
            getSystemDefaults, getSystemInfo, getSystemReboot,
            getSystemVersion, getUpdateInfo, putUpdateToVersion,
        }}>
            {children}
        </ApiContext.Provider>
    )
}

export default ApiContext;