import React from 'react'
import {  Spinner, Modal } from "react-bootstrap";


const Loading = ({ isLoading }) => {

    return (


    <Modal
        show={isLoading}
        backdrop="static" // Imposta lo sfondo grigio trasparente
        keyboard={false}
        centered
        contentClassName="custom-modal" // Aggiungi una classe personalizzata per lo stile del modal
      >
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
          </div>
        </Modal.Body>
      </Modal>
    )
}

export default Loading