import { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useApi from '../hooks/useApi';
import { Col, Button, Row, Container, Card, Form} from "react-bootstrap";
import Loading from '../components/modal/Loading'

const Login = () => {
    const { setAuth } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const { login } = useApi()
    const from = location.state?.from?.pathname || "/"

    const userRef = useRef()
    const pwdRef = useRef()
    const errRef = useRef()

    const [user, setUser] = useState('admin')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState('')

    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault()

        errRef.current.style.display = "none";

        try {
            // Mostra lo spinner prima della chiamata
            setLoading(true);
            const response = await login(user, pwd)
            setAuth(response?.data?.access_token)
            setLoading(false);
            // setUser('')
            setPwd('')
            navigate(from, { replace: true })
        } catch (err) {
            setLoading(false);
            if (!err?.response) {
                setErrMsg('Nessuna risposta dal server')
            } else if (err.response?.status === 400) {
                setErrMsg('Username o Password mancante')
            } else if (err.response?.status === 401) {
                setErrMsg('Non autorizzato');
            } else {
                setErrMsg('Login fallito');
            }
            //errRef.current.focus();
            errRef.current.style.display = "block"; 
        }
    }

    return (

      

      <Container>
      <Loading isLoading={loading} />
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <div className="border border-3 border-primary"></div>
          <Card className="shadow">
            <Card.Body>
              <div className="mb-3 mt-md-4">
                <div className="mb-3">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      className="mb-3"
                    >
                      <Form.Label className="text-center">
                        Username
                      </Form.Label>
                      <Form.Control
                        id="username"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        required
                        disabled
                        type="text"
                        placeholder="Inserire username"
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                    >
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Inserire password"
                        id="password"
                        ref={pwdRef}
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                      />
                    </Form.Group>
                    <div className="d-grid ">
                      <Button className="text-white" variant="primary" type="submit">
                        Accedi
                      </Button>
                    </div>
                    <div ref={errRef} className="invalid-feedback" role="alert">
                        {errMsg}
                    </div>

                    
        
           
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container> 
    


    
    )


}

export default Login