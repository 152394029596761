import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/modal/Loading'
import { Form, Container, Col, Row, Button, Table, ListGroup, InputGroup, BsLock } from "react-bootstrap";
import useApi from '../hooks/useApi'


const CameraItem = ({
    camera: c, camIndex: i,
    updateShowState, updateCamParam, autodetectProfiles, changeCamSelectedProfile
}) =>
    <ListGroup.Item key={i}>
        <div className="d-flex align-items-center justify-content-between" >
            <div onClick={() => updateShowState(i, !c.show)}>

                <span className="icon-size text-primary fw-bold me-2">
                    {!c.show && <>
                        <i className="bi bi-caret-down-fill"></i>
                    </>}
                    {c.show && <>
                        <i className="bi bi-caret-up-fill"></i>
                    </>}
                </span>

                <span className="icon-size text-primary fw-bold">
                    <i className="bi bi-camera-video-fill"></i> Telecamera {i + 1}
                </span>
            </div>

            {!c.known && <>
                <div className="ms-auto">
                    <Form.Check
                        type="switch"
                        id={`cameraEnabled-${i}`}
                        name={`use-cam${i}-chb`}
                        checked={c.use}
                        onChange={() => updateCamParam(i, 'use', !c.use)}
                        disabled={c.known ? 'true' : undefined}
                    />
                </div>
            </>}

        </div>

        {c.show && (
            <>
                <div className="mt-2" style={{ textAlign: 'right' }}>
                    {c.ip}<br />
                    {c.mac}
                </div>
                {!c.known && <>
                    <div className="d-flex align-items-center mt-2">
                        <span className="icon-size text-primary fw-bold me-2">
                            <i className="bi bi-camera-video-fill"></i>
                        </span>
                        <Form.Control type='text'
                            name={`name-cam${i}-txt`}
                            
                            value={c.name}
                            onChange={e => updateCamParam(i, 'name', e.target.value)}
                        />
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <span className="icon-size text-primary fw-bold me-2">
                            <i className="bi bi-person-fill"></i>
                        </span>
                        <Form.Control type='text'
                            name={`username-cam${i}-txt`}
                            value={c.username}
                            placeholder='Username'
                            onChange={e => updateCamParam(i, 'username', e.target.value)}
                        />
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <span className="icon-size text-primary fw-bold me-2">
                            <i className="bi bi-key-fill"></i>
                        </span>
                        <Form.Control
                            type='text'
                            name={`password-cam${i}-txt`}
                            value={c.password}
                            placeholder='Password'
                            onChange={e => updateCamParam(i, 'password', e.target.value)}
                        />
                    </div>
                    <div className="mt-2" style={{ textAlign: 'center' }}>
                        <Button
                            name={`profile-cam${i}-btn`}
                            className="text-white"
                            variant="primary"
                            onClick={() => autodetectProfiles(i)}
                            disabled={(c.username && c.password) ? undefined : 'true'}
                        >
                            Ricerca Profili
                        </Button>
                    </div>
                </>}
                {c.profiles ? (
                    <div className="mt-2">
                        {
                            c.profiles.length > 0 ? (
                                c.profiles.map((p, j) => (
                                    <Form.Check
                                        key={j}
                                        name={`fc-cam${i}-profile`}
                                        id={`fc-cam${i}-profile${j}`}
                                        type="radio"
                                        value={p.name}
                                        checked={p.selected}
                                        onChange={e => changeCamSelectedProfile(i, e.target.value)}
                                        label={
                                            <div>
                                                <span className="fw-bold">{p.name}</span>
                                                <br />
                                                {p.uri}
                                            </div>
                                        }
                                    />
                                ))
                            ) : (
                                <p>Nessun profilo</p>
                            )
                        }
                    </div>
                ) : null}
            </>)}
    </ListGroup.Item>


const Autodetect = () => {
    const navigate = useNavigate()
    const { getAutodetectDiscovery, postAutodetectProfiles, postCamera } = useApi()

    const [cameras, setCameras] = useState([])
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [enableDiscovery, setEnableDiscovery] = useState(true)

    const updateShowState = (index, newValue) => {
        // Crea una copia dell'array delle fotocamere
        const updatedCameras = [...cameras];

        // Aggiorna il valore di c.show per l'elemento specifico
        updatedCameras[index].show = newValue;

        // Aggiorna lo stato con la nuova copia dell'array
        setCameras(updatedCameras);
    };


    const discoverQuery = useQuery('discover', getAutodetectDiscovery, {
        onSuccess: response => {
            let camerasNew = []
            setEnableDiscovery(false)
            camerasNew.push(
                ...response.data?.new.map((a, i) => ({
                    ip: a.ip,
                    mac: a.mac,
                    known: false,
                    use: false,
                    enabled: true,
                    audio_enabled: true,
                    name: `Telecamera ${i + 1}`,
                    username: '',
                    password: '',
                    profiles: null,
                    show: false,
                    showProfiles: false
                }))
            )
            camerasNew.push(
                ...response.data?.known.map(a => ({
                    ip: a.ip,
                    mac: a.mac,
                    known: true,
                    use: true,
                    show: false,
                    showProfiles: false
                }))
            )
            setCameras(camerasNew)
        },
        enabled: enableDiscovery,
    })

    function updateCamParam(i, paramName, value) {
        let camerasNew = cameras.slice()
        camerasNew[i][paramName] = value
        //MG - Qui la logica deve portare a mostrare i profili solo se esistono
        camerasNew[i]["showProfiles"] = true
        setCameras(camerasNew)
    }

    function autodetectProfiles(i) {
        let cam = cameras[i]
        postAutodetectProfiles({
            address: cam.ip,
            username: cam.username,
            password: cam.password,
        })
            .then(response => {
                let profiles = response.data.map((profile, i) => ({ ...profile, selected: i === 0 }))
                updateCamParam(i, 'profiles', profiles)
                updateCamParam(i, 'use', true)
            })
            .catch(() => alert('Errore nella ricerca dei profili.\nRicontrollare username e password.'))
    }

    function changeCamSelectedProfile(i, value) {
        let newProfiles = cameras[i].profiles.map(profile => ({ ...profile, selected: profile.name === value }))
        updateCamParam(i, 'profiles', newProfiles)
    }

    async function autodetectConfirm() {
        let camToUse = cameras.filter(c => !c.known && c.use)
        // controllo che tutte le telecamere selezionate abbiano il profilo
        if (!camToUse.every(c => !!c.profiles)) {
            let camWOProfile = camToUse.find(c => !c.profiles)
            alert('Controllare il profilo della telecamera con indirizzo ' + camWOProfile.ip)
        }
        else {
            let newCameraConf = camToUse.map(c => {
                let address = c.profiles.find(p => p.selected).uri
                // inserisco nell'indirizzo la stringa <username>:<password>@ dopo //
                let index = address.indexOf('//') + 2
                address = address.slice(0, index) + `${c.username}:${c.password}@` + address.slice(index)
                return {
                    address,
                    name: c.name,
                    enabled: c.enabled,
                    audio_enabled: c.audio_enabled,
                }
            })
            await postCamera(newCameraConf).then(() => {
                navigate('/settings')
            })
        }
    }

    return (
        <main className='Main'>
            <Loading isLoading={discoverQuery.isFetching} />
            {
                discoverQuery.isLoading && <p>Caricamento...</p>
            }
            {
                !discoverQuery.isLoading && discoverQuery.isError && <p>Errore: {discoverQuery.error.message}</p>
            }
            {
                !discoverQuery.isLoading && !discoverQuery.isError && <>
                    <Container>
                        <Row >
                            <Col lg={12} className="mb-4">
                                <h3 className="text-primary"><i className="bi bi-camera-video-fill"></i> Autodetect</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6} className="mb-4">
                                <h3 className="text-primary">Telecamere nuove</h3>
                                <ListGroup>
                                    {
                                        cameras.map((c, i) =>
                                            !c.known && <CameraItem
                                                camera={c}
                                                camIndex={i}
                                                updateShowState={updateShowState}
                                                updateCamParam={updateCamParam}
                                                autodetectProfiles={autodetectProfiles}
                                                changeCamSelectedProfile={changeCamSelectedProfile}

                                            />
                                        )}
                                </ListGroup>
                            </Col>
                            <Col sm={12} lg={6} className="mb-4">
                                <h3 className="text-primary">Telecamere già note</h3>
                                <ListGroup>
                                    {
                                        cameras.map((c, i) =>
                                            c.known && <CameraItem
                                                camera={c}
                                                camIndex={i}
                                                updateShowState={updateShowState}
                                                updateCamParam={updateCamParam}

                                            />
                                        )}
                                </ListGroup>
                            </Col>
                        </Row>
                        <Row >
                            <Col lg={12} className="mb-4">
                                <div className="d-flex justify-content-end">
                                    <Button
                                        id="autodetect-discard-btn"
                                        name="autodetect-discard-btn"
                                        className="text-white"
                                        variant="primary"
                                        onClick={() => navigate(-1)}
                                    >
                                        Annulla
                                    </Button>
                                    <Button
                                        id={`autodetect-confirm-btn`}
                                        className="text-white ms-1"
                                        variant="primary"
                                        onClick={() => autodetectConfirm()}
                                    >
                                        Salva
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
            }
        </main>
    )
}

export default Autodetect