import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Form, Container, Col, Row, Button } from "react-bootstrap";
import classNames from 'classnames';
import Loading from '../components/modal/Loading'
import AlertModal from '../components/modal/AlertModal';
import TwoButtonsModal from '../components/modal/TwoButtonsModal';
import useApi from '../hooks/useApi';
import './_style.css'

const IPV4_REGEX = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/

const Network = () => {
    const { getEth0Conf, putEth0Conf } = useApi()

    const [mode, setMode] = useState('auto')
    const [modeCur, setModeCur] = useState('auto')
    const [ipAddr, setIpAddr] = useState('')
    const [ipAddrCur, setIpAddrCur] = useState('')
    const [macAddr, setMacAddr] = useState('')
    const [snMask, setSnMask] = useState('')
    const [snMaskCur, setSnMaskCur] = useState('')
    const [gwAddr, setGwAddr] = useState('')
    const [gwAddrCur, setGwAddrCur] = useState('')

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState()
    const [showPopup, setShowPopup] = useState(false)


    const fillNetworkConf = c => {
        setMode(c.dhcp ? 'auto' : 'manual')
        setModeCur(c.dhcp ? 'auto' : 'manual')
        setIpAddr(c.inet ?? '')
        setIpAddrCur(c.inet ?? '')
        setSnMask(c.netmask ?? '')
        setSnMaskCur(c.netmask ?? '')
        setGwAddr(c.gateway ?? '')
        setGwAddrCur(c.gateway ?? '')
        setMacAddr(c.ether ?? '')
    }

    const { error, isFetching, isError } = useQuery('network', getEth0Conf, {
        onSuccess: response => fillNetworkConf(response.data)
    })
    const mutation = useMutation(putEth0Conf, {
        onSuccess: response => {
            // let modeNew = response.data.dhcp ? 'auto' : 'manual'
            // let ipAddrNew = response.data.inet
            // if (modeNew !== modeCur && modeNew === 'auto') {
            //     setAlert("L'indirizzo IP potrebbe essere cambiato, è necessario reinserirlo nel browser.")
            // }
            // else if (ipAddrNew !== ipAddrCur) {
            //     setAlert("L'indirizzo IP è cambiato. Sta per essere reindirizzato sul nuovo indirizzo.")
            //     setTimeout(() => {
            //         window.location.replace(`http://${ipAddrNew}`);
            //     }, 3000)
            // }
            fillNetworkConf(response.data)
        },
        onError: e => setAlert("Errore. La sua richiesta non è stata eseguita."),
    })

    const handleOpenPopup = () => setShowPopup(true)
    const handleClosePopup = () => setShowPopup(false)
    const handleRispondiClick = () => {
        setShowPopup(false)
        // visualizza spinner per 10 secondi
        setLoading(true)
        setTimeout(() => setLoading(false), 10000)
        // invia richiesta di modifica configurazione di rete
        mutation.mutate({
            dhcp: mode === 'auto',
            inet: ipAddr,
            netmask: snMask,
            gateway: gwAddr,
        })
    }

    const modeChange = e => {
        setMode(e.target.value)
    }

    function validateNetworkConf() {
        if (mode === 'auto') {
            return true 
        } else {
            return IPV4_REGEX.test(ipAddr) && IPV4_REGEX.test(snMask) && IPV4_REGEX.test(gwAddr)
        }
    }

    function submitNetworkConf(e) {
        e.preventDefault()
        if (validateNetworkConf()) {
            handleOpenPopup()
        } else {
            setAlert("Errore. I parametri inseriti non sono validi.")
        }
    }

    function discardNetworkConf() {
        setMode(modeCur)
        setIpAddr(ipAddrCur)
        setSnMask(snMaskCur)
        setGwAddr(gwAddrCur)
    }

    let modifiedNetwork = mode !== modeCur || ipAddr !== ipAddrCur || snMask !== snMaskCur || gwAddr !== gwAddrCur

    return (
        <>
            <Loading isLoading={loading || isFetching} />
            <AlertModal
                alert={alert}
                clear={() => setAlert(null)}
            />
            {showPopup && (
                <TwoButtonsModal
                    title="Modifica configurazione di rete"
                    question="Sei sicuro di voler procedere?"
                    buttons={[
                        {
                            label: "Annulla",
                            variant: "secondary",
                            action: handleClosePopup,
                        },
                        {
                            label: "Conferma",
                            variant: "primary",
                            className: "text-white",
                            action: handleRispondiClick,
                        },
                    ]}
                />
            )}
            {
                isError && <div className="d-flex justify-content-center align-items-center invalid-feedback" role="alert">Errore: {error.message}</div>
            }
            {
                !isError && <>
                    <Container>
                        <Row >
                            
                            <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-4"> 
                                <h3 className="text-primary"><i className="bi bi-hdd-network"></i> Rete</h3>
                                <Form className="mt-4">

                                    <Row className="mb-4">
                                        <Col>
                                            <Form.Check
                                                type="radio"
                                                id="network-manual-rdb"
                                                name="network-mode-rdb"
                                                label="Manuale"
                                                value="manual"
                                                checked={mode === 'manual'}
                                                onChange={modeChange}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="radio"
                                                id="network-auto-rdb"
                                                name="network-mode-rdb"
                                                label="DHCP"
                                                value="auto"
                                                checked={mode === 'auto'}
                                                onChange={modeChange}
                                            />
                                        </Col>
                                    </Row>

                                    <Form.Group
                                        className="mb-3"

                                    >
                                        <Form.Label className="text-center">
                                            Indirizzo MAC
                                        </Form.Label>
                                        <Form.Control
                                            id="network-mac-addr-txt"
                                            name="network-mac-addr-txt"
                                            value={macAddr}
                                            disabled={true}
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"

                                    >
                                        <Form.Label className="text-center">
                                            Indirizzo IP
                                        </Form.Label>
                                        <Form.Control
                                            id="network-ip-addr-txt"
                                            name="network-ip-addr-txt"
                                            value={ipAddr}
                                            onChange={e => setIpAddr(e.target.value)}
                                            disabled={mode === 'auto'}
                                        />
                                    </Form.Group>



                                    <Form.Group
                                        className="mb-3"

                                    >
                                        <Form.Label className="text-center">
                                            Maschera
                                        </Form.Label>
                                        <Form.Control
                                            id="network-sn-mask-txt"
                                            name="network-sn-mask-txt"
                                            value={snMask}
                                            onChange={e => setSnMask(e.target.value)}
                                            disabled={mode === 'auto'}
                                        />
                                    </Form.Group>


                                    <Form.Group
                                        className="mb-3"

                                    >
                                        <Form.Label className="text-center">
                                            Gateway
                                        </Form.Label>
                                        <Form.Control
                                            id="network-gw-addr-txt"
                                            name="network-gw-addr-txt"
                                            value={gwAddr}
                                            onChange={e => setGwAddr(e.target.value)}
                                            disabled={mode === 'auto'}
                                        />
                                    </Form.Group>

                                    
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                id="network-discard-eth0-btn"
                                                name="network-discard-eth0-btn"
                                                className="text-white"
                                                variant="primary"
                                                onClick={discardNetworkConf}
                                                disabled={!modifiedNetwork}
                                            >
                                                Annulla
                                            </Button>
                                            <Button
                                                id="network-set-eth0-btn"
                                                name="network-set-eth0-btn"
                                                className={classNames("text-white ms-1", modifiedNetwork && "btnHighlight")}
                                                variant="primary"
                                                onClick={submitNetworkConf}
                                            >
                                                Salva
                                            </Button>
                                        </div>
                                    


                                </Form>
                            </Col>
                        </Row>

                    </Container>


                </>
            }
        </>
    )
}

export default Network