import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Layout from './components/Layout/Layout';
import RequireAuth from './components/RequireAuth';
import Autodetect from './views/Autodetect';
import Camera from './views/Camera';
import Missing from './views/Missing';
import Login from './views/Login';
import Network from './views/Network';
import Profile from './views/Profile';
import Settings from './views/Settings';
import Status from './views/Status';
import System from './views/System';

function App() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="/login" element={<Layout showMenu={false} />} >
        <Route index element={<Login />} />
      </Route>

      <Route path="/" element={<Layout showMenu={true} />} >
        {/* private routes*/}
        <Route element={<RequireAuth />} >
          <Route index element={<Status />} />
          <Route path="autodetect" element={<Autodetect />} />
          <Route path="camera" element={<Camera />} >
            <Route path=":cameraId" element={<Camera />} />
          </Route>
          <Route path="network" element={<Network />} />
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
          <Route path="system" element={<System />} />
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
