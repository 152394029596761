import React from 'react'
import { Col , Row, Container } from "react-bootstrap";

const Footer = () => {
    const today = new Date();
    return (


<footer className="bg-primary text-white py-4 fixed-bottom">
  <Container>
    <Row>
      <Col className="d-flex justify-content-center align-items-center h-100">
        <p className="text-center my-auto">© {new Date().getFullYear()} Loqed</p>
      </Col>
    </Row>
  </Container>
</footer>
    )
}

export default Footer