import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import NavBar from './NavBar'
import Footer from './Footer'

const Layout = ({ showMenu }) => {
    return (
       

<div className="app-container">
<div className="header-container">
  <Header title="APOLLO.Video" showMenu={showMenu} />

  {showMenu && <NavBar />}


</div>
<div className="content-container">
  <Outlet />
</div>
<Footer></Footer>
</div>

    )
}

export default Layout