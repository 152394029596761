import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const AlertModal = ({ alert, clear }) => {
	return (
		<Modal show={!!alert} onHide={clear} >
			<Modal.Header closeButton>
				<Modal.Title>Attenzione</Modal.Title>
			</Modal.Header>
			<Modal.Body>{alert}</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={clear}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default AlertModal