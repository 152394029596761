import React from 'react'
import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
    return (


<Nav variant="tabs" defaultActiveKey="/">
      <Nav.Item>
        <Nav.Link as={NavLink} to="/" exact="true"><i className="bi bi-bar-chart-line-fill"></i> Panoramica</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/settings" exact="true"><i className="bi bi-sliders"></i> Impostazioni</Nav.Link>
      </Nav.Item>
      
      <Nav.Item>
        <Nav.Link as={NavLink} to="/network" exact="true"><i className="bi bi-hdd-network"></i> Rete</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/system" exact="true"><i className="bi bi-display"></i> Sistema</Nav.Link>
      </Nav.Item>
    </Nav>


    )
}

export default NavBar