import React, { useState, useEffect, useRef } from 'react'
import useApi from '../hooks/useApi';
import useAuth from '../hooks/useAuth'
import { Col, Button, Row, Container, Card, Form} from "react-bootstrap";
import Loading from '../components/modal/Loading'

// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
// const PWD_REGEX = /^[a-zA-Z0-9!@#$%]{4,24}$/


const Profile = () => {
    const { deleteAuth } = useAuth()
    const { putProfile } = useApi()
    const errRef = useRef()

    const [oldPwd, setOldPwd] = useState('')
    const [pwd, setPwd] = useState('')
    const [matchPwd, setMatchPwd] = useState('')

    const [validPwd, setValidPwd] = useState(false)
    const [validMatch, setValidMatch] = useState(false)
    // const [pwdFocus, setPwdFocus] = useState(false)
    const [matchFocus, setMatchFocus] = useState(false)

    const [errMsg, setErrMsg] = useState('')
    const [success, setSuccess] = useState(false)

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // setValidPwd(PWD_REGEX.test(pwd))
        setValidPwd(pwd.length > 0)
        setValidMatch(pwd === matchPwd)
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('')
    }, [oldPwd, pwd, matchPwd])

    const handleSubmit = async e => {
        e.preventDefault()
        // if button enabled with JS hack
        // if (!PWD_REGEX.test(pwd)) {
        //     setErrMsg("Invalid Entry")
        //     return;
        // }
        setLoading(true);
        await putProfile("admin", oldPwd, pwd)
            .then(response => {
                deleteAuth()
                setSuccess(true)
                setOldPwd('')
                setPwd('')
                setMatchPwd('')
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (!err?.response) {
                    setErrMsg('Nessuna risposta dal server')
                } else if (err.response?.status === 401) {
                    setErrMsg('Password errata')
                } else {
                    setErrMsg('Operazione fallita')
                }
                errRef.current.focus()
            })
    }

    return (
        <Container>
            <Loading isLoading={loading} />
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <div className="border border-3 border-primary"></div>
          <Card className="shadow">
            <Card.Body>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase">VESTA CLOUD</h2>
                <p className="mb-5">Aggiornamento Profilo</p>
                <div className="mb-3">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      className="mb-3"
              
                    >
                      <Form.Label className="text-center">
                        Username
                      </Form.Label>
                      <Form.Control
                        id="username"
                        autoComplete="off"
                        value="admin"
                        required
                        disabled
                        type="text"
                      />
                    </Form.Group>


                    <Form.Group
                      className="mb-3"
                    >
                      <Form.Label>Vecchia Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Inserire vecchia password"
                        id="oldPassword"
                        onChange={(e) => setOldPwd(e.target.value)}
                        value={oldPwd}
                        required
                      /> 
                      </Form.Group>

                      <Form.Group
                      className="mb-3"
                    >
                      <Form.Label>Nuova Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Inserire nuova password"
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                      /> 
                      </Form.Group>

                      <Form.Group
                      className="mb-3"
                    >
                      <Form.Label>Conferma Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Reinserire nuova password"
                        id="confirm_pwd"
                        onChange={(e) => setMatchPwd(e.target.value)}
                        onFocus={() => setMatchFocus(true)}
                        value={matchPwd}
                        required
                      /> 
                      </Form.Group>


                    <div className="d-grid ">
                      <Button disabled={!validPwd || !validMatch ? true : false} className="text-white" variant="primary" type="submit">
                        Set
                      </Button>
                    </div>
                    <p ref={errRef} >{errMsg}</p>

                    <div ref={errRef} className="invalid-feedback" role="alert">
                        {errMsg}
                    </div>
                    
                    {matchFocus && !validMatch && <p>
                                    La password di conferma deve corrispondere con la nuova password inserita.
                                </p>}
                                {success &&
                <p>
                    Password modificata con successo.<br />
                    <a href="/login">Effettua nuovamente il login</a>
                </p>
            }            
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container> )

        {/*<main className='Main'>
            <h2>Profilo</h2>
            <form onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label htmlFor="username">Username:</label>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    id="username"
                                    autoComplete="off"
                                    value="admin"
                                    required
                                    disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="oldPassword">Vecchia password:</label>
                            </td>
                            <td>
                                <input
                                    type="password"
                                    id="oldPassword"
                                    onChange={(e) => setOldPwd(e.target.value)}
                                    value={oldPwd}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="password">Nuova password:</label>
                            </td>
                            <td>
                                <input
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                    // onFocus={() => setPwdFocus(true)}
                                />
                            </td>
                            <td>
                                {/* {pwdFocus && !validPwd && <p>
                                    Inserire una password tra 4 e 24 caratteri.<br />
                                    La password può includere maiuscole, minuscole, numeri e caratteri speciali.<br />
                                    Caratteri speciali consentiti: <span aria-label="punto esclamativo">!</span> <span aria-label="chiocciola">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollaro">$</span> <span aria-label="percento">%</span>
                                </p>} */}
                           {/* </td>
                        </tr>
                        <tr>
                            <td>
                                <label htmlFor="confirm_pwd">Conferma Password:</label>
                            </td>
                            <td>
                                <input
                                    type="password"
                                    id="confirm_pwd"
                                    onChange={(e) => setMatchPwd(e.target.value)}
                                    value={matchPwd}
                                    required
                                    onFocus={() => setMatchFocus(true)}
                                />
                            </td>
                            <td>
                                {matchFocus && !validMatch && <p>
                                    La password di conferma deve corrispondere con la nuova password inserita.
                                </p>}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <p ref={errRef} >{errMsg}</p>
                        </tr>
                        <tr>
                            <button
                                className='btn-90px'
                                disabled={!validPwd || !validMatch ? true : false}
                            >
                                Set
                            </button>
                        </tr>
                    </tfoot>
                </table>
            </form>
            {success &&
                <p>
                    Password modificata con successo.<br />
                    <a href="/login">Effettua nuovamente il login</a>
                </p>
            }
        </main> */}
    
}

export default Profile