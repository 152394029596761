import React, { useState } from 'react'
import { useQuery } from 'react-query'
import useApi from '../hooks/useApi';
import { ListGroup, Container, Col, Row } from "react-bootstrap";
import Loading from '../components/modal/Loading'

const status_italiano = {
    'active': 'attivo',
    'connected': 'connesso',
    'disabled': 'disabilitato',
    'disconnected': 'disconnesso',
    'inactive': 'inattivo',
    'running': 'in esecuzione',
    'stopped': 'fermato',
    'unknown': 'ignoto',
}

const status_icon = {
    'active': {icon: 'bi-check-circle-fill icon-size green-color'},
    'connected': {icon: 'bi-check-circle-fill icon-size green-color'},
    'disabled': {icon: 'bi-exclamation-circle-fill icon-size red-color'},
    'disconnected': {icon: 'bi-exclamation-circle-fill icon-size red-color'},
    'inactive': {icon: 'bi-exclamation-circle-fill icon-size red-color'},
    'running': {icon: 'bi-check-circle-fill icon-size green-color'},
    'stopped': {icon: 'bi-exclamation-circle-fill icon-size red-color'},
    'unknown': {icon: 'bi-exclamation-circle-fill icon-size red-color'}
}



const Status = () => {
    const { getStatus } = useApi()

    const [status, setStatus] = useState()
    const [loading, setLoading] = useState(true)
    
    const { error, isLoading, isError } = useQuery('status', getStatus, {
        onSuccess: response => setStatus(response.data),
        onSettled: () => setLoading(false),
        refetchInterval: 10000
    })

    return (
        <>
            <Loading isLoading={loading} />
                    {
                        !isLoading && isError && <div className="d-flex justify-content-center align-items-center invalid-feedback" role="alert">Errore: {error.message}</div>
                    }
                    {
                        !isLoading && !isError && <>
                <Container>
                    <Row>
                    <Col sm={12} lg={6} className="mb-4"> 
                            <h3 className="text-primary"><i className="bi bi-ui-checks"></i> Stato</h3>
                            <ListGroup>
                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                    <span className="fw-bold">Processo di ricezione audio/video</span>
                                    <span><i className={`bi ${status_icon[status?.lqdIPCStreamManager?.status]?.icon}`}></i></span>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                    <span className="fw-bold">Processo di gestione degli allarmi</span>
                                    <span><i className={`bi ${status_icon[status?.lqdIPCAlarmReceiver?.status]?.icon}`}></i></span>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                    <span className="fw-bold">Processo di comunicazione bus e Cloud</span>
                                    <span><i className={`bi ${status_icon[status?.vestacloud?.status]?.icon}`}></i></span>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                    <span className="fw-bold">Bus</span>
                                    <span><i className={`bi ${status_icon[status?.vestacloud?.info?.bus]?.icon}`}></i></span>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                    <span className="fw-bold">Cloud</span>
                                    <span><i className={`bi ${status_icon[status?.vestacloud?.info?.cloud]?.icon}`}></i></span>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={12} lg={6} className="mb-4"> 
                            <h3 className="text-primary"><i className="bi bi-camera-video-fill"></i> Telecamere</h3>

                            {
                                status?.cameras?.length > 0 ? (
                                    <ListGroup>

                                        {
                                            status.cameras.map(c =>
                                                <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                                    <span className="fw-bold">{c.name}</span>
                                                    <span><i className={`bi ${status_icon[c.status]?.icon}`}></i></span>
                                                </ListGroup.Item>

                                            )
                                        }

                                    </ListGroup>) : (
                                    <p>Nessuna telecamera</p>
                                )
                            }



                        </Col>
                    </Row>
                </Container>
                </>
                    }
</>
                
    
    )
}

export default Status